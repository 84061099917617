.flipper {
  padding-bottom: 100%;
  position: relative;
  -webkit-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.flipper-img {
  width: 219px;
  height: 219px;
  object-fit: cover;
  border-radius: 10px;
}

.flipper-card {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (min-width: 576px) {

  .ratio-not-1-1 {
    padding-bottom: calc(50% - 5px);
  }

}

.flipper-front,
.flipper-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.flipper-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flipper-card.flipper-is-flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.calculator-home-wrapper {
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
}

.section-wrapper {
  padding: 2em 0;
}

.section-gray {
  background-color: #f9f8f7;
}

.section-calculator {
  background-color: #f9f8f7;
  background-image: url(../../assets/img/ellipse.png);
  background-repeat: repeat;
  background-size: auto;
  background-position: top left;

  padding-top: 80px;
}

.calculator-icon-wrapper {
  display: flex;
  align-items: center;
}

.calculator-icon-wrapper img {
  max-width: 80px;
}

.calculator-icon-wrapper {
  margin-top: 15px;
}

.excellence-wrapper {
  position: relative;
  display: inline-block;
}

.excellence-content {
  position: absolute;
  top: 15px;
  left: 15px;
}

.col-media {
  position: relative;
  text-align: center;
}

.col-media:last-child::after {
  display: none;
}

.col-media::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(-10px, -50%);
  border: 3px solid #E7AF40;;
}

.section-review {
  position: relative;
  overflow: hidden;
}

.section-review-art {
  position: absolute;
}

.section-review-art--left {
  top: 30%;
  left: 0;
  transform: translateY(-50%);
}

.section-review-art--right {
  top: 30%;
  right: 0;
  transform: translateY(-50%);
}

.section-review-art--right img {
  transform: scaleX(-1);
}

.review-slide-container {
  padding-left: 15px;
  overflow-x: scroll;
}

.review-slide-container .swiper-container {
  padding: 20px 0;
}

.section-car {
  background-image: url(../../assets/img/ellipse.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top left;
  overflow: hidden;
  height: 620px;
}

.car-carousel-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.car-carousel-wrapper:last-child {
  margin-bottom: 0;
}

.car-carousel-text {
  position: absolute;
  top: 140px;
  z-index: 3;
}

.car-carousel-text p {
  font-size: 12px;
}

.car-carousel-text--name {
  color: #fff;
}

.car-carousel-text--discount {
  color: #E7AF40;
}

.car-carousel-text--price {
  color: #fafafa;
  text-decoration: line-through;
}

.car-carousel-vertical {
  display: inline-block;
}

.car-carousel-wrapper::after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, .3);
  z-index: 2
}

.car-carousel-wrapper-overlay::after {
  background-color: rgba(255, 255, 255, .7);
}

.col-carouse-car-2 {
  margin-top: 80px;
  /* transform: translateY(-100px); */
}

.col-carouse-car-3 {
  margin-top: -200px;
  /* transform: translateY(-100px); */
}

.col-carouse-car-4 {
  margin-top: -300px;
  /* transform: translateY(-100px); */
}

.section-footer-bottom {
  background-color: #070F2A;
  color: #fff;
}

.footer-bottom-menu {
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .section-wrapper .title-big {
    font-size: 24px;
  }

  .calculator-hero-art {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .excellence-wrapper {
    margin-bottom: 15px;
  }

  .testimoni-content::after {
    display: none;
  }

  .testimoni-content::before {
    display: none;
  }

  .col-media {
    margin-bottom: 15px;
  }

  .col-media::after {
    display: none;
  }

  .col-carouse-car-3, .col-carouse-car-4 {
    display: none;
  }

  .car-carousel-wrapper-overlay::after {
    background-color: rgba(0, 0, 0, .3);
  }

  .media-img {
    margin-left: auto;
    margin-right: auto;
  }

  .col-footer-bottom-menu {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    margin-bottom: 30px;
  }

  .section-review-art {
    width: 100px;
    top: 40%;
  }
}