.popup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, .7);
  opacity: 0;
  display: none;
  transition: all .3s ease-in;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.popup.popup--show {
  opacity: 1;
  display: flex;
}

.popup-content {
  background-color: #fff;
  padding: 40px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.input-range-calculator .input-range__label--value {
  display: none;
}

.input-range-calculator .input-range__label {
  color: #070F2A;
  font-family: 'Libre Franklin', sans-serif;
}

.input-range-calculator .input-range__label-container {
  left: 0;
}

.input-range-calculator .input-range__label--max .input-range__label-container {
  left: 0;
}

.input-range-calculator .input-range__slider {
  background: var(--main-color);
  border-color: var(--main-color);
}

.input-range-calculator .input-range__track--active {
  background: var(--main-color);
}

.input-range-text {
  position: relative;
}

.dummy-input-range-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

@media (max-width: 768px) {
  .popup-content {
    padding: 20px;
  }

  .popup-content {
    width: 90%;
    height: 80%;
    overflow: scroll;
  }

  .btn-calc-save {
    margin-top: 10px;
  }
}