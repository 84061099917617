
.select-dummy {
  border: 1px solid #b5cdc4;
  display: inline-block;
  padding: 10px 5px;
  position: relative;
  width: 100%;
  border-radius: 30px;
}

.select-dummy label {
  font-size: 20px;
}

.select-dummy select {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.select-dummy .select-dummy-arrow {
  color: var(--main-color);;
}

.select-dummy-arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.select-value-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.select-value-item {
  white-space: nowrap;
}

.select-custom-dropdown {
  position: absolute;
  bottom: -10px;
  transform: translateY(100%);
  left: 0;
  width: 100%;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: none;
  z-index: 9997;
}

.select-custom-dropdown.select-custom-dropdown--show {
  display: block;
}

.select-custom-dropdown ul {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
}

.select-custom-dropdown ul::-webkit-scrollbar {
  width: 6px;
}

.select-custom-dropdown ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.select-custom-dropdown ul::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #C4C4C4;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
}

.select-custom-dropdown ul::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255,0,0,0.4); 
}

.select-custom-dropdown li {
  padding: 5px 10px;
  border-bottom: 1px solid #E8E8E8;
  font-size: 20px;
  cursor: pointer;
}

.select-custom-dropdown li:last-child {
  border-bottom: 0;
}

.select-placeholder {
  color: #ccc;
}

.select-value-item {
  font-size: 20px;
}

@media (max-width: 768px) {
  .select-custom-dropdown li {
    font-size: 16px;
  }

  .select-value-item {
    font-size: 16px;
  }
}