.step1-container {
  margin-top: 50px;
}

.row-gap {
  margin-bottom: 20px;
}

hr {
  border: 1px solid #fff;
  border-color: rgb(229, 229, 229);
}

.error-message {
  display: block;
  margin: 10px;
  color: red;
}

.box-timer {
  background-color: #F2F2F2;
  padding: 25px;
  border-radius: 10px;
}

.box-timer--countdown {
  color: #3B70BB;
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 900;
}

.toggle-action {
  cursor: pointer;
}

.toggle-section {
  max-height: 0px;
  transition: all .2s ease-in;
  overflow: hidden;
}

.toggle-section.toggle-section--open {
  max-height: 500px;
}

.row-hr {
  border: 1px solid #E8E8E8;
  width: 100%;
  margin: 30px 0;
}

.paylease-accordion-container ol {
  padding-left: 25px;
  list-style-position: outside;
}

.qris-code img {
  width: 100%; 
}

.gap-3 {
  margin-right: 0.5rem;
}

.flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.mr--0-5 {
  margin-right: 0.5rem;
  margin-bottom: 0; /* Adjust this value as needed */
}

.img-icon {
  max-width: 24px;
  max-height: 24px;
  width: auto;
  height: auto;
}


.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 3px;
}

.flex-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 3px;
}

@media (max-width: 768px) {
  .step1-container {
    margin-top: 30px;
  }
}

/*Syariah button*/
/* .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .7em 1.5em;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2);
  outline: 0;
  border: 0;
}

.btn:focus {
  outline: 0;
}

.btn:hover {
  box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
}

.btn:disabled {
  box-shadow: none;
  background-color: rgb(161, 161, 161);
}

.btn--green {
  background-color: #008000;
  color: #fff;
}

.btn--accent {
  background-color: #fff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

@media (max-width: 768px) {
  .btn {
    margin-top: 1em;
    font-size: 12px;
  }
} */