.footer-inline-logo {
  display: flex;
  align-items: center;
}

.footer-container {
  padding: 10px 0;
  border-top: 1px solid #F2F2F2;
  margin-top: 20px;
}

.footer-inline-text {
  margin-right: 10px;
}

.footer-copyright {
  text-align: right;
}

@media (max-width: 768px) {
  .footer-inline-logo {
    display: block;
  }

  .footer-inline-text {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .footer-copyright {
    margin-top: 20px;
    text-align: left;
  }

  .mb-sm--1 {
    margin-bottom: 1em;
  }
}