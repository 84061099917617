body {
  font-size: 14px;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.mb--0 {
  margin-bottom: 0;
}

.mb--1 {
  margin-bottom: 1em;
}

.mb--2 {
  margin-bottom: 2em;
}

.mt--0 {
  margin-top: 0;
}

.mt--1 {
  margin-top: 1em;
}

.mt--2 {
  margin-top: 2em;
}

.mr--0 {
  margin-right: 0;
}

.mr--1 {
  margin-right: 1em;
}

.mr--2 {
  margin-right: 2em;
}

.ml--0 {
  margin-left: 0;
}

.ml--1 {
  margin-left: 1em;
}

.ml--2 {
  margin-left: 2em;
}

.pb--0 {
  padding-bottom: 0;
}

.pb--1 {
  padding-bottom: 1em;
}

.pb--2 {
  padding-bottom: 2em;
}

.pt--0 {
  padding-top: 0;
}

.pt--1 {
  padding-top: 1em;
}

.pt--2 {
  padding-top: 2em;
}

.pr--0 {
  padding-right: 0;
}

.pr--1 {
  padding-right: 1em;
}

.pr--2 {
  padding-right: 2em;
}

.pl--0 {
  padding-left: 0;
}

.pl--1 {
  padding-left: 1em;
}

.pl--2 {
  padding-left: 2em;
}

.cursor-pointer {
  cursor: pointer;
}