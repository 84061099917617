.input-otp-wrapper .form--input {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.otp-input-row {
  display: flex;
  margin: 10px 0;
}

.otp-info-text {
  font-size: 20px;
}

.otp-input-col {
  margin-right: 10px;
}

.otp-input-col:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .input-otp-wrapper .form--input {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  
  .otp-input-row {
    display: flex;
    justify-content: space-between;
  }

  .otp-input-col {
    margin-right: 0;
  }

  .otp-info-text {
    font-size: 16px;
  }
}
