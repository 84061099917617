.testimoni-star {
  color: #E7AF40;
}

.testimoni-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.testimoni-slide {
  text-align: center;
}

.testimoni-name {
  color: #070F2A;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.testimoni-occupation {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.testimoni-content {
  position: relative;
}

.right-testimoni-image {
  position: fixed;
  top: 220px;
  right: 30px;
  z-index: -1;
  height: 70px;
}

.left-testimoni-image {
  position: fixed;
  top: 220px;
  left: 30px;
  z-index: -1;
  height: 70px;
}

.cmd-slide-container .swiper-button-prev::after, .cmd-slide-container .swiper-button-next::after {
  font-size: 12px;
  background-color: #E7AF40;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
}