.big-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9998;
  overflow: scroll;
}

.feature-link-col {
  margin-bottom: 50px;
}

.big-menu__main {
  list-style: none;
  margin: 0;
  padding: 0;
}

.big-menu__main li {
  margin-bottom: 40px;
}

.big-menu__main li a {
  font-size: 3em;
}

.big-menu__art {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.big-menu-logo img {
  max-width: 100%;
}

.big-menu_header {
  padding: 15px 0;
}

@media (max-width: 768px) {
  .big-menu__art {
    display: none;
  }

  .big-menu_header {
    padding: 5px 0;
  }

  .big-menu__main li {
    margin-bottom: 20px;
  }

  .big-menu__main li a {
    font-size: 24px;
  }

  .big-menu__main {
    margin-bottom: 40px;
  }

  .feature-link-col {
    margin-bottom: 20px;
  }

  .feature-link-col .text--size-24 {
    font-size: 18px;
  }
}