html.no-scroll {
  height: 100vh;
  overflow: hidden;
}

header.header--scroll {
  background-color: #fff;
  box-shadow: 0 4px 12px rgb(0 0 0 / 8%), 0 0 1px rgb(1 0 0 / 10%);
}

.main-page-wrapper, .sidebar-page-wrapper {
  padding-top: 100px;
}

.main-page-wrapper {
  min-height: calc(100vh - 100px);
}

.big-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.big-menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.page-container {
  margin-top: 50px;
  padding-bottom: 20px;
}

.main-page-container {
  padding-left: 15px;
  padding-right: 15px;
}

.main-footer-container {
  padding-left: 15px;
  padding-right: 15px;
}

.fx {
  display: flex;
}

.title-big {
  font-size: 48px;
}

.title-medium {
  font-size: 32px;
}

p {
  font-size: 20px;
}

.fx {
  display: flex;
}

.whatsapp{
	position:fixed;
	width:50px;
	height:50px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color: white;
	border-radius:50px;
	text-align:center;
  font-size:30px;
  box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.2);
  z-index:100;
  transition: all .2s ease-in;
  display: block;
}

.whatsapp:hover {
  color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.whatsapp-icon {
  position: absolute;
  display: block;
  line-height: 0;
  font-size: 26px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-page {
  padding: 48px 0;
}

input, select {
  font-size: 20px !important;
}

@media (max-width: 768px) {
  .sidebar-page-wrapper {
    padding-top: 80px;
    width: 100%;
  }

  .main-page-wrapper {
    padding-top: 30px;
    min-height: calc(100vh - 340px);
  }

  .page-container {
    margin-top: 20px;
  }

  p {
    font-size: 16px;
  }

  input, select {
    font-size: 16px !important;
  }

  .main-page-container, .main-footer-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}