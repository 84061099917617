.accordion-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.accordion-career-icon {
  text-align: right;
}

.accordion-career-icon span {
  cursor: pointer;
  font-size: 25px;
}