.review-box-avatar {
  display: flex;
  align-items: center;
}

.review-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 20%);

  min-height: 250px;
}

@media (max-width: 768px) {
  .review-box {
    min-height: 170px;
  }
}