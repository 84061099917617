.career-hero {
  /* background-color: #E5E5E5; */
  height: 600px;
  padding-top: 150px;

  background-image: url(../assets/img/career-art.jpg);
  background-repeat: repeat-x;
  background-size: auto;
  background-position: bottom;
}

.career-accordion-row {
  border-bottom: 1px solid #F2F2F2;
  cursor: pointer;
}

.modal-career-wrapper {
  max-width: 550px;
  max-height: 90vh;
  overflow-y: scroll;
}

.modal-career-body {
  padding: 25px;
}

.modal-career-header {
  position: relative;
}

.modal-career-header-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  color: #fff;
}

.modal-career-header-button {
  position: relative;
  margin-top: -25px;
  padding: 0 25px;
}