.main-footer {
  padding: 48px 0;
  background-color: #f8f7f6;
  background-image: url(../../assets/img/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.cursor-pointer {
  cursor: pointer;
}

.social-height {
  height: 16px;
}

.top-footer {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #BDD3CB;
}

.main-footer-social span {
  font-size: 12px;
}

.top-footer-logo img {
  width: 100px;
}

.img-absolute-bottom {
  position: absolute;
  bottom: 0px
}

@media (max-width: 768px) {
  .main-footer-copy {
    margin-bottom: 30px;
  }
}