.refund-container {
    padding: 20px;
    margin-top: 100px;
  }
  
  .refund-title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* .refund-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  h2 {
    font-size: 24px;
    margin-top: 20px;
  }

  h3 {
    font-size: 20px;
    margin-top: 20px;
  }

  .text {
    text-align: justify;
  }
  
  .text-p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  
  .policy-description-footer {
    font-size: 16px;
    font-style: italic;
    margin-top: 32px;
    text-align: center;
    margin-bottom: 20px;
  }

  .item-list {
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  @media screen and (max-width: 768px) {
    .policy-description-footer {
      font-size: 12px; /* Adjust the font size for mobile */
    }
  }
  