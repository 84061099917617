.box-gray {
  background-color: #F2F2F2;
  padding: 25px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .box-gray {
    padding: 15px;
  }

  .box-col {
    margin-bottom: 10px;
  }
}